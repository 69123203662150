@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,600;1,400&family=Playfair+Display:ital,wght@0,400;1,600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app {
  height: 100%;
  background-color: #010026;
  @apply text-white;
}